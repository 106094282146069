.product-slices
  display: flex
  flex-direction: row
  flex-wrap: nowrap

  border-radius: 0.25rem
  overflow: hidden

  .slice
    padding: 0.25rem
    text-align: center

    &.white-on-color
      color: white
      font-weight: bold
