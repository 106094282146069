#fold_property_type_house,
#fold_property_type_flat,
#fold_auxiliary_heating_type,
#fold_photovoltaic_panels
  display: block !important
  transition: all 0.2s ease-in-out

  &.hidden
    opacity: 0
    max-height: 0
    overflow: hidden
  &:not(.hidden)
    opacity: 1
    max-height: 1000vh // trick to allow bloc animation

.summary-border
  border-bottom: 1px solid $gris-clair
  strong
    text-align: right
.summary-ul
  list-style: none
  text-align: right
  li
    font-weight: bolder
