div[data-oembed-type='video']
  position: relative
  height: 0
  //overflow: hidden
  margin-left: auto
  margin-right: auto
  margin-bottom: spacer(4)
  margin-top: spacer(4)
  // not sure why this rule was there...but nok for a page like demenagement !
  //@media #{$breakpoint-larger-than-sm-768px}
  //  max-width: 50%

  & iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
