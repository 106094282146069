.table-content.inner-table
  padding: 0
  border-bottom: 0

.account-attribute__name
  text-transform: capitalize
  font-weight: 500

.contract-block
  border-top: 1px solid $bleu-light
  &:nth-child(even)
    background-color: $light-color
.contract-sites
  padding-bottom: 0

.table-content.sites
  border-bottom: 0

.table-line.site:last-child
  border-bottom: 0

.account-info__title
  *
    margin-right: 16px
    margin-bottom: 0
    align-self: flex-end
    text-transform: none

.account-title
  margin-right: 16px
  width: fit-content

input.account-form__element, .account-form__element
  margin-bottom: 0

.account-section__title
  margin-bottom: 16px