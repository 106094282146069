.prismic-image
  @include prismic-image

.prismic-html
  a
    color: $bleu
    &:hover
      color: $bleu-dark
  ul
    // Remove default bullets
    list-style: none

    li
      position: relative

      &::before
        // \2022 is the Unicode for a bullet, \25CF for black circle
        color: $bleu
        content: "\25CF"
        display: inline-block
        font-size: 80%
        left: -1.5rem
        position: absolute
        top: -2px

  .block-img
    text-align: center
