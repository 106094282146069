.parrainage-links
  .link
    display: inline-block
    margin-right: spacer(2)
  i
    color: $blanc
    background: $turquoise
    border-radius: 50%
    font-size: 1.5em
    padding: 0.5rem
