a
  span.circle
    width: 2em
    height: 2em
    text-align: center
    border-radius: 50%
    color: $blanc
    line-height: 2em
    background-color: $gris
    display: inline-block
  &.active, &:hover
    span.circle
      background-color: $bleu-dark

.border-tab
  border: 1px solid $gris
  &:hover
    border: 1px solid $bleu-dark

.tab-container
  display: flex
  .step-button
    flex-grow: 2
  .btn-small
    margin-right: 1em

@media #{$breakpoint-larger-than-md-992px}
    i.lg-disabled
      display: none

@media #{$breakpoint-smaller-than-md-992px}
  .tab-container
    display: block
    span
      display: inline-block
    i.sm-disabled
      display: none

@media #{$breakpoint-smaller-than-sm-768px}
  .tab-container
    .btn-small
      display: flex
      flex-wrap: wrap
      width: auto
      margin-bottom: 1em
