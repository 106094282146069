.container-light-color
  background-color: $light-color

  .header-title + &
    margin-top: -1 * spacer(4)
    padding: spacer(2) 0 spacer(4)
    margin-bottom: spacer(4)

.news-banner
  .actualite
    flex: 1
    padding-bottom: spacer(3)
  .buttons
    padding-bottom: spacer(8)
